<template>
  <div>
	<div class="center-text margin-bottom-100" style="padding: 10px;">
	<h2 class="center-text"><b>{{lang[this.appLang].tapni_tags}}</b></h2>

	<h5 class="center-text regularbold">
	  <span v-if="user.tags && user.tags.length > 0">{{lang[this.appLang].tapni_tags_p1}}</span>
	  <span v-else v-html="lang[this.appLang].tapni_tags_p2"></span>
	</h5>


	<div v-if="user.tags && user.tags.length >= 4" class="center-text half-top">
		<LinkIcon @click.native="toggleQRScanModal"
                :link-style="'link-grid'"
                :data="{ type: 'activatetag', text: lang[this.appLang].activate_tag }"
                :editing="false"
                :pulse="user.tags && user.tags.length === 0"
                :class="{ 'add-button': true }" />
	</div>
	<hr v-if="user.tags && user.tags.length > 4" class="decoration decoration-lines-thin color-black margin-center full-top half-bottom" style="width: 85%!important;">

	<div v-if="user.tags && user.tags.length > 0">
	  <TagIcon v-for="tag in user.tags" :key="tag.id" :data="tag" :disabled="!tag.is_active" class="full-top" />
	  <br>
	</div>

	<div v-if="user.tags && user.tags.length < 4" class="center-text half-top">
		<LinkIcon
        @click.native="toggleQRScanModal"
        :link-style="'link-grid'"
        :data="{ type: 'activatetag', text: lang[this.appLang].activate_tag }"
        :editing="false"
        :pulse="user.tags && user.tags.length === 0"
        :class="{ 'add-button': true }" />
	</div>
	<hr v-if="user.tags && user.tags.length > 0 && user.tags.length <= 4" class="decoration decoration-lines-thin color-black margin-center full-top half-bottom" style="width: 85%!important;">
	</div>
  </div>
</template>

<script>
/* eslint-disable */
import LinkIcon from '../components/LinkIcon'
import TagIcon from '../components/TagIcon'
import { mapActions } from 'vuex'
import {EventBus} from '../store/event-bus'
export default {
  name: 'Tags',
  components: {
    TagIcon,
    LinkIcon,
  },
  methods: {
  	...mapActions(['getUser']),
    toggleQRScanModal () {
      if(!this.rules.addTags) EventBus.$emit('toggleForbiddenRuleModal')
      else EventBus.$emit('toggleQRScanModal')
    },
    async init () {
	    if (!this.isLoggedIn) return this.$router.push('/welcome')

	    if (this.user && this.user.username !== this.storage.username) {
		    this.$store.commit('setLoading', true)
		    await this.getUser({ username: this.storage.username })
	    }
      if (this.route.hash === '#activate') {
        if(this.rules.addTags) this.toggleQRScanModal()
        this.$router.push({ hash: '' })
      }
      this.$store.commit('setInitialize', true)
      this.$store.commit('setLoading', false)
    }
  },
  mounted () {
    if (this.route) this.init()
  },
  watch: {
    'route.name' (nv, ov) {
      if (!ov && nv) this.init()
    },
    'route.hash' (nv, ov) {
      if (!ov && nv) this.init()
    }
  },
  computed: {
    rules (){
      return this.$store.getters.rules
    }
  }
}
</script>

<style scoped>
</style>
