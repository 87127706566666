<template>
  <div style="display: inline-block; text-align: center;">
    <a @click="click(data)" :class="'user-tag pointer ' + (disabled ? ' opacity-50' : '')" style="transition: transform 0.5s;">
      <img :src="data.photo" class="user-tag-img" :alt="data.text" />
    </a>
	<br>
	<a @click="toggleTagModal(data)" class="button white-button small-top pointer" style="margin: 0 5px;">
        {{lang[this.appLang].configure}}
	</a>
  </div>
</template>

<script>
import {EventBus} from '../store/event-bus'
export default {
  name: 'TagIcon',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    click () {
      EventBus.$emit('toggleTagModal', this.data)
    },
    toggleTagModal (tag) {
      EventBus.$emit('toggleTagModal', tag)
    }
  }
}
</script>

<style scoped>
  .edit-icon {
    border-radius: 20px;
    right: -2%; top: -10%;
    position: absolute;
    z-index:555;
    max-width: 15%;
	overflow: hidden;
  }
</style>
